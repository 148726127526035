
/* main menu in wordpress */

ul.ulmenu{
  margin:0;
  padding:0;

  &:after{
    .beforeafter;
    clear:both;
  }

  li.menu-item{
    list-style:none;
  }

}