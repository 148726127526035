.mainslider{
  min-height:600px;
}

.owl-carousel {
  margin-bottom:50px;
}

.nominheight{
  min-height:0!important;
}

/* video row */
.video{
  background:url(../img/videobanner.jpg) no-repeat center center;
  background-size:100% 100%;

  @media (max-width:768px){
    background-position:80% center;
    background-size:cover!important;

  }

  .transition;

  &:hover{
    background-size:120% 120%;
  }

  //min-height:550px;
  width:100%;
  //margin-top:40px;

  padding-top:60px;
  padding-bottom:60px;

  iframe{
    display:block;
  }

  .contentside{
    color:#fff;
    text-align:center;
    padding-top:50px;
    padding-bottom:30px;
    font-size:16px;
    line-height:32px;

    h2{
      .unica;
      text-transform:uppercase;
      &:after{
        content:"";
        display:block;
        max-width:100px;
        width:100%;
        height:2px;
        background:#fff;
        margin:8px auto;
      }
    }

    a{
      max-width:300px;
      margin-left:auto;
      margin-right:auto;
      .unica;
    }

  }

}

.sponsors_home{
  background:#fff;
  padding-top:40px;
  padding-bottom:60px;

  h2{
    text-transform:uppercase;
    display:block;
    margin:0 auto 20px;
    text-align:center;
    .unica;
  }

}

/* vertical image */

.sponsors-carousel{
  margin-bottom:0;
  .owl-item{
    display:inline-block!important;
    vertical-align: middle!important;
    height:100%!important;
    height: 100px!important;
    img{

      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      width: 120px!important;
      height: auto;
      max-height: 100px;

      .transition;
      opacity:1;

      &:hover{
        opacity:.8;
      }

    }
  }
}

.mainslider{
  position:relative;
  overflow:hidden;
}

.edgeleft{
  display:block;
  position:absolute;
  bottom:-50px;
  right:0;
  width: 0;
  height: 0;
  border-top:150px solid transparent;
  border-bottom:50px solid transparent;
  border-right:1920px solid #eee;
  z-index:9000;
  z-index:4;
}
.edgeright{
  display:block;
  position:absolute;
  top:-50px;
  left:0;
  width: 0;
  height: 0;
  border-top:50px solid transparent;
  border-bottom:150px solid transparent;
  border-left:1920px solid #030303;
  z-index:9000;
  display:none;
}

.audiobg{
  display:block;
  position:fixed;
  bottom:0;
  right:0;
  width: 300px;
  height: 120px;
  background:#fff;
  box-shadow:5px 5px 5px #333;
}

.welcometext{
  padding-top:80px;
  padding-bottom:80px;
  position:relative;
  z-index:5;
  margin-top:-80px;
  font-size:16px;
  line-height:32px;
  .btn1{
    max-width:200px;
    margin:0 auto 20px;
    .unica;
  }

  h2{
    .unica;
  }

}

.teampic{
  position:relative;
  overflow:hidden;
  z-index:4;

  background-size:100% 100%;
  background-position:center center;
  background-repeat:no-repeat;

  min-height:580px;
  background-size:cover;
  position:relative;
  margin-top:-100px;

  &:after{
    content:"";
    display:block;
    position:absolute;
    top:-50px;
    left:0;
    width: 0;
    height: 0;
    border-bottom:150px solid transparent;
    border-top:50px solid transparent;
    border-left:1920px solid #eee;
    z-index:9000;
  }

}

.slogan{
  position: absolute;
  top: 20%;
  left: auto;
  color: #fff;
  text-transform: uppercase;
  z-index: 9;
  font-size: 130px;
  right: auto;
  width: 100%;
  text-align: center;
  color:#eee;
  .unica;
  @media (max-width:734px){
    font-size:80px;
    top:40%;
  }

  @media (max-width:734px){
    font-size:60px;
  }

}

@media (max-width:1280px){
  .welcometext{
    padding-top:50px;
    padding-bottom:50px;
  }
}

@media (max-width:960px){
  .welcometext{
    padding-top:0;
    padding-bottom:0;
  }
  .mainslider{
   min-height:0;
  }
}

@media (max-width:568px){
  .homeSlider, .homeSlider .sliderItem, .owl-carousel .owl-item{
    height:420px;
  }
}