@keyframes productlinkFadeIn {
  from {
    opacity:0;
    top:10px;
  }
  to {
    opacity:1;
    top:0px;
  }
}

.productContainer{
  min-height:380px;
  //border-top-left-radius:4px;
  //border-top-right-radius:4px;
  //overflow: hidden;

  &:hover{
    cursor: pointer;

    .productimage{
      img{
        .magnify;
      }

      &:after{
        opacity:.6;
      }
    }

    .productinfo{
      h4 a{
        background:@blue;
        padding-top:18px;
        padding-bottom:18px;
      }
    }

    .productlink{
      opacity:1;
      animation-name: productlinkFadeIn;
      animation-duration: .3s;
      animation-timing-function: ease-out;
    }

  }

}

.productimage{
  position:relative;
  overflow: hidden;

  img{
    .transition;
    position:relative;
    z-index:3;
  }

  &:after{
    .transition;
    .beforeafter;
    .absoluteTopLeft;
    width:100%;
    height:100%;
    background:#000;
    opacity:0.2;
    z-index:5;
  }

}

.productinfo{

  h4{
    margin:0;
    padding:0;
    a{
      .karla;
      color:#fff;
      text-transform:uppercase;
      font-size:16px;
      text-decoration:none;
      display:block;
      text-align:center;
      display:block;
      padding:15px;
      background:#111;
      .transition;

      &:hover{
        background:@blue;
      }

    }
  }
}

.productprice{
  position:absolute;
  left:15px;
  top:15px;
  z-index:7;
  color:#fff;
  .unica;
  font-size:20px;
  text-transform:uppercase;
}

.productlink{
  .transition;
  opacity:0;

  span{
    border:1px solid #fff;
    display:inline-block;
    padding:5px 15px;
    margin:0 auto;
    text-align:center;
    min-width:120px;
    position:relative;
    top:calc(50% - 18px);
    i{
      margin-left:5px;
    }
  }
  width:100%;
  height:100%;
  text-align:center;
  text-transform:uppercase;
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index:7;
  color:#fff!important;
  .unica;
  font-size:16px;
}

.productCategories,.paginationContainer{
  margin-bottom:50px;
  .btn1{
    float:left;
  }
  :after{
    .beforeafter;
  }
}

.shopnowbutton{
  i{
    font-size:12px;
    margin-right:10px;
  }
}

@media (max-width:480px){

  /* full width for mobile */
  .productContainer{
    min-height:0;
  }

  .productCategories{
    a.btn1{
      display:block;
      float:none;
    }
    margin-bottom:20px;
  }

  .mobile_catheading{
    display:block!important;
    font-weight:700;
    text-transform:uppercase;
  }

  .paginationContainer{
    //background:#131313;
    .pageof{
      padding-top:30px;
    }
  }

  .mobilecentertext{
    text-align:center;
  }

  .mobilenogutter{
    padding-left:0;
    padding-right:0;
    .productimage{
      min-height:255px;
      background-size:cover;
      background-position:center center;
    }

    .shopnowbutton{
      margin-top:0!important;
      width:100%!important;
      padding-top:30px!important;
      padding-bottom:30px!important;
    }

  }

  .footer{
    margin-top:0;
  }

}