.sponsor-item{

  overflow:hidden;

  .thumb{
    border:1px solid #eee;
    display:block;

    img{
      display:block;
      text-align:center;
      margin:auto;
    }

  }

  h3{
    font-size:20px;
  }

}

.sponsorlogo{
  max-width:400px;
  margin:0 auto 5px;
  display:block;
}

.sponsorItem{
  position:relative;
  background:#000;
}

.postedit{
  background:#000;
  padding:10px;
  display:inline-block;
  position:absolute!important;
  right:0!important;
  top:0!important;
  z-index:7;
  color: #fff!important;
  font-size: 16px;
  .transition;
  &:hover{
    background:@blue;
  }
}

