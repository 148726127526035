.karla{
  font-family: 'Karla', sans-serif;
  font-weight:normal!important;
}
.unica{
  font-family: 'Unica One', cursive;
  font-weight:normal!important;
}

/* color */
@red:#FE0000;
@yellow:#FFCC01;
@blue:#0267FF;
@dark:#171717;

/* global typo */
body{
  .karla;
  font-size:16px;
}