.pageBanners{

  .bannerContents{
    min-height:243px;
    width:100%;
  }

  width:100%;
  position:relative;
  margin-bottom:50px;

  background-position:center center!important;
  background-size:cover!important;

  &:after{
    .beforeafter;
    .absoluteTopLeft;
    width:100%;
    height:100%;
    background:#000;
    opacity:.3;
    z-index:3;
  }

  .bannerContents{
    color:#fff;
    z-index:5;
    text-align:center;

    h2{
      .unica;
      text-transform:uppercase;
      font-size:36px;
      &:after{
        .beforeafter;
        width:100px;
        height:1px;
        background:#fff;
        margin:0 auto;
      }
    }

    p{
      .karla;
      font-size:18px;
    }

  }

  @media (max-width:480px){
    margin-bottom:0;
  }

}