body.page-id-9 { // membership page id

  .pageContainer{
    background-size:contain;
    background-repeat:no-repeat;
    background-position:left top;
    min-height:642px;
    position:relative;
  }

  .pageBanners {
    background-position:center center!important;
    margin-bottom:0;

    .logobanner{
      display:block;
      margin:30px auto 50px;
    }

  }

  .footer{
    margin-top:0;
  }

  .pageBanners .bannerContents {
    //padding-top:40px;
    //padding-bottom:60px;

    .membershipbanner{
      max-width:900px;
      margin:0 auto 40px;
    }

    h2 + p {
      //margin-bottom:20px;
    }
  }
}

.collapseContent{
  background:#fefefe;
  width:100%;
  //margin-bottom:10px;
  padding:30px;
  //border-bottom:2px solid #ccc;

  height:auto;

  h2{
    font-size:24px;
    .karla;
  }

  //border-left:2px solid #ccc;

  &:after{
    .beforeafter;
    clear:both;
  }

}

.collapseContent_click{
  //border-left:2px solid @blue;
  text-decoration:none!important;
  display:block;
  width:100%;
  line-height:20px;
  background:#222;
  padding:10px 30px;
  color:#fff!important;
  text-transform:uppercase;
  position:relative;
  font-size:14px;

  i{
    float:right;
    margin-top:4px;
  }

  .transition;
  &:hover{
    background:@blue;
  }

  &:after{
    content:"";
    display:block;
    display:none;
    width: 0;
    height: 0;
    border-top:10px solid transparent;
    border-bottom:10px solid transparent;
    border-left:10px solid transparent;
    position:absolute;
    left:0;
    bottom:-10px;
  }

  &.active{
    //margin-top:1px;
    background:#000;

    &:after{
      border-left-color:#131313;
    }

  }

  &.active:first-child{
    margin-top:0!important;
  }

  &.active + .collapseContent{
    margin-bottom:0!important;
  }

}

.scrolldown{
  background:#131313;
  color:#fff!important;
  font-size:21px;
  border-radius:20px;
  text-align:center;
  margin:0 auto;
  width:40px;
  height:40px;
  display:block;
  line-height:42px;
}

.pv,.nx{
  color:@blue!important;
}

@media (max-width:480px){

  .pv{
    float:left;
  }
  .nx{
    float:right;
  }

  .nx,.pv{
    //display:none;
    display:inline-block;
    background:@blue;
    color:#fff!important;
    text-decoration:none!important;
    padding:5px 15px;
    .transition;
    &:hover{
      background:@yellow;
      color:#131313!important;
    }
  }
}

.nogutter{
  padding:0;
}

.becomemember{
  .unica;
  opacity:1;
  background:@blue;
  padding:30px 15px;
  width:100%;
  font-size:20px;

  &.active{
    background:#333;
  }

}

.membership_application_wrapper{

  h4{
    text-transform:uppercase;
    color:#fff;
    border-bottom:1px solid #fff;
    padding-bottom:10px;
    margin-bottom:20px;
  }

  background:#131313;
  //border-radius:20px;

  //max-width:600px;
  width:100%;
  margin:30px auto 50px;
  padding:30px;

  max-width:800px;

  label{

    display:block;

    span{
      color:#efefef;
      display:block;
    }
  }

  input,textarea,select{
    .nooutline;
    .transition;
    border:none;
    padding:8px 10px;
    width:100%;
    background:#222;
    border-bottom:2px solid #444;
    color:#fff;
    font-size:16px;

    &:focus{
      background:#333;
      border-color:@blue;
    }
  }

  textarea{
    height:80px;
  }

  &:after{
    content:"";
    display:block;
    clear:both;
  }
}

.membership-type{
  display:block;
  padding-top:10px;
}

#membership-type-moreinfo1,#membership-type-moreinfo2{
  color:#fff;
}

.groupwrapper{
  margin-bottom:30px;
  border-left:3px solid @yellow;
  padding:10px;
  padding-left:30px;
  margin-left:15px;
  color:#fff;
  background:#222;

  p:last-child{
    margin-bottom:0;
  }
  div p:last-child{
    margin-bottom:0;
  }

  i{
    font-size:14px;
    margin-right:5px;
  }

}

.wpcf7-radio,.wpcf7-checkbox{

  display:block;

  label,span,input{
    display:inline-block!important;
    width:auto!important;
  }
  span{
    cursor: pointer;
  }

  input{
    margin-right:10px;
  }

  .wpcf7-list-item{
    margin:0!important;
    width:100%!important;
    display:block!important;
  }

}

.membershipColumn{
  //float:left;
  position:relative;
  z-index:5;

  &.membershipColumn1{
    //overflow: hidden;
  }

  &.membershipColumn2{
    //background:#131313;
  }

  &:after{
    content:"";
    display:block;
    clear:both;
  }

}

.clear{
  clear:both;
}

.hidden{
  display:none;
}

#membershipcontent{

  padding:0;
  max-width:800px;
  margin:0 auto;

  @media (max-width:1280px){
    max-width:640px;
  }
}

.membership_application_wrapper{

  @media (max-width:1280px){
    max-width:640px;
  }

  //border-top:3px solid #000;

  //opacity:0;
  //position:relative;
  //left:150px;

  //transition:all .3s ease-in-out;
  //-moz-transition:all .3s ease-in-out;
  //-webkit-transition:all .3s ease-in-out;
  //-o-transition:all .3s ease-in-out;
  //
  //-webkit-transform: scale(1.88);
  //-moz-transform: scale(1.88);
  //-ms-transform: scale(1.88);
  //-o-transform: scale(1.88);
  //transform: scale(1.88);

  &.active{
    opacity:1;

    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  .wpcf7-submit{
    margin-top:20px;
    background:@blue;
    color:#fff;

    &:focus{
      background:@blue;
      color:#fff;
    }

    &:hover{
      background:@yellow;
      color:#131313;
    }

    cursor: pointer;
    text-transform:uppercase;
    padding-top:20px;
    padding-bottom:20px;
    border:none;
  }

  div.wpcf7-response-output{
    font-size:18px!important;
  }

}

.bgf{
  background:#131313;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index:3;
}

@media (max-width:1280px){
  body.page-id-9 {
    .pageContainer {
      background-size:cover!important;
    }
  }
}

@media (max-width:480px){
  body.page-id-9 {
    .pageBanners {
      .logobanner{
        max-width:60%;
      }
    }

    .membershipColumn1{
      //display:none;
    }

  }
}

.whitetext{
  color:#fff;
  margin-bottom:20px;

  & + .radio{
    margin-top:-20px;
  }

}

.membership_application_wrapper{

  position:relative;

  h4{
    font-size:16px;
    position:relative;

    &:after{
      content:"";
      display:block;
      position:absolute;
      bottom:0;
      left:0;
      height:2px;
      width:10%;
      background:@yellow!important;
      .transitionslow;
    }

    span{
      margin-top:2px;
      float:right;
      font-size:12px;
      font-weight:normal!important;
    }
  }

  &[data-step='1'] h4:after{ width:10%; background:@blue; }
  &[data-step='2'] h4:after{ width:20%; background:@yellow;}
  &[data-step='3'] h4:after{ width:30%; background:@red;}
  &[data-step='4'] h4:after{ width:40%; background:@blue;}
  &[data-step='5'] h4:after{ width:50%; background:@yellow;}
  &[data-step='6'] h4:after{ width:60%; background:@red;}
  &[data-step='7'] h4:after{ width:70%; background:@blue; }
  &[data-step='8'] h4:after{ width:80%; background:@yellow;}
  &[data-step='9'] h4:after{ width:90%; background:@red;}
  &[data-step='10'] h4:after{ width:100%; background:@blue;}

  .prev{
    float:left;
  }
  .next{
    float:right;
  }
  label{
    span{
      text-transform:uppercase;
      font-size:14px;
      margin-bottom:10px;
    }
    br{
      display:none;
    }
  }

  input[type="radio"],input[type="checkbox"]{
    position:relative;
    top:2px;
  }

  .wpcf7-radio,.wpcf7-checkbox{
    label,span{
      margin-bottom:0!important;
    }
  }

  .radio{
    padding-left:10px;
    margin-bottom:10px;
  }

}

.multipartform {
  &:after{
    .beforeafter;
    clear:both;
  }
}

.pulltogutter{
  margin-left:-30px;
  margin-right:-30px;
  margin-bottom:30px;
}
.nomarginbottom{
  margin-bottom:0!important;
}

.imagesliderwrapper{
  float:left;
  width:250px;
  height:250px;
  background:#eee;
  margin-right:30px;
  margin-bottom:40px;
  background-position:center center;
  background-size:cover;
  position:relative;

  & + p{
    float:left;
  }
  & + p + p{
    clear:both;
  }

  br{
    display:none;
  }

  span{
    color:#fff;
    display:block;
    position:absolute;
    left:0;
    bottom:-30px;
    width:100%;
    text-align:center;
  }

  .p,.n{
    position:absolute;
    top:42%;
    background:#000;
    text-transform:uppercase;
    text-decoration:none;
    color:#fff;
    padding:8px 10px;
    opacity:.3;
    .transition;
    &:hover{
      opacity:1;
    }
  }

  .p{
    left:0;
  }
  .n{
    right:0;
  }

  img{
    display:none;
  }
}

.btn1.show{
  float:left;
}

.formsnavigator{
  color:#fff;
  background:#666;
  position:fixed;
  top:12.8%;
  left:-200px;
  font-size:14px;
  padding:0;
  ul,ol{
    padding:0;
    margin:0;
    list-style:none;
    li{
      .transition;
      display:block;
      padding:8px 18px;
      cursor: pointer;
      opacity:.5;
      position:relative;
      left:0;
      &:hover{
        opacity:1;
      }
    }
    li.active{
      opacity:1;
      background:#333;
    }
  }
}

.membership_application_wrapper.showall{
  .multipartform{
    h4{
      margin-top:50px;
    }
  }
  .multipartform.part1{
    h4{
      margin-top:0;
    }
  }
}

@media (max-width:1280px){
  .formsnavigator{
    top:15.5%;
  }

  .sponsorsgrid_5col{
    .sponsorinfo {
      h2,.url{
        display:none;
      }
    }
  }

}

@media (max-width:1024px){
  .formsnavigator{
    display:none!important;
  }
}

@media (max-width:480px){
  .membershipColumn{
    padding-left:0;
    padding-right:0;
  }
  .membership_application_wrapper{
    margin-top:0;
  }
  .collapseContent_click{
    padding-left:15px;
    padding-right:15px;
  }
  .collapseContent,.membership_application_wrapper{
   padding:15px;
  }
}