.contactformwrapper{

  label{
    display:block!important;
    .karla;
    text-transform:uppercase;
    font-weight:700;
    font-size:16px;
  }

  input,textarea{
    width:100%;
    display:block;
    border:none;
    .nooutline;
    padding:5px;
    font-size:16px;
    margin-top:5px;
    border-left:2px solid #fff;
    .transition;
    background:#fff;
    &:focus{
      background:#fafafa;
      border-left:2px solid @yellow;
    }
  }

  .wpcf7-submit{
    background:@blue;
    color:#fff;
    font-weight:700!important;
    text-transform:uppercase;
    display:inline-block!important;
    width:auto!important;
    cursor: pointer;
    padding-left:30px;
    padding-right:30px;
    margin-top: 30px;
    .transition;
    &:hover,&:focus{
      background:@yellow;
      color:#131313;
    }
  }

}

div.wpcf7-response-output{
  font-size:20px!important;
  margin-left:0!important;
  margin-right:0!important;
  background:#ccc!important;
}

.sociallink{
  .transition;
  font-size:16px;
  text-decoration:none!important;
  position:relative;
  padding-left:40px;
  text-transform:uppercase;
  i{
    font-size:24px;
    position:absolute;
    top:0;
    left:0;
  }

}

@media (max-width:480px){
  .contactformwrapper {
    textarea {
      height:200px;
    }
    .wpcf7-submit{
      width:100%!important;
    }
  }

  .finduscolumn{
    margin-bottom:40px;
  }

}

body.page-template-page-contact{
  .footer{
    margin-top:100px;
  }
}