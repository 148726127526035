/* sidebar style */
body{
  .menuSideHead{
    .unica;
    font-size:20px;
    color:#fff;
    text-transform:uppercase;
    padding-top:21px;
    padding-bottom:20px;
    display:block;
    width:100%;

    .closeSidebar{
      font-size:15px;
      float:right;
      position:relative;
      cursor:pointer;
      top:5px;
      padding: 5px;
      color:#131313;
      background: #444;
      border-radius: 10px;
      width: 20px;
      height: 20px;
      line-height: 11px;
      text-align: center;
      .transition;
      &:hover{
        opacity:.8;
      }
    }

    background: #131313;  /* fallback for old browsers */
    //background: -webkit-linear-gradient(to right, #93291E, #FE0000);  /* Chrome 10-25, Safari 5.1-6 */
    //background: linear-gradient(to right, #93291E, #FE0000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }

  .cbp-spmenu{
    background:#030303;
  }

  /* link */
  .cbp-spmenu-vertical {

    z-index:9999;

    a{
      font-size:16px;
      text-decoration:none;
      text-transform:uppercase;
      border-color:#111;
      padding:15px 20px;
      .nooutline;
      .transition;
      &:hover{
        background:@red;
        padding-left:25px;
      }
    }
  }

}

@media (max-width:480px){
  .cbp-spmenu{
    .searchside{
      display:inline-block!important;
      width:100%;
    }
    .fa-search{
      float:right;
      position:relative;
      top:3px;
    }
  }
}