.footer,.copyright{
  background:#030303;
}

.footer{

  //margin-top:100px;
  padding-top:50px;

  h4{
    .unica;
    font-size:20px;
    text-transform:uppercase;
    color:#fff;
    margin-bottom:30px;

    /*bars*/
    &:after{
      content:"";
      display:block;
      width:100%;
      max-width:100px;
      height:1px;
      background:@red;
      margin-top:5px;
    }

    &.red:after{
      background:@red;
    }
    &.blue:after{
      background:@blue;
    }
    &.yellow:after{
      background:@yellow;
    }
    &.white:after{
      background:#fff;
    }

  }

  .footerLink{

    &.social{

      a{
        padding-left:30px;
      }

    }

    a{
      display:block;
      text-decoration:none;
      color:rgba(0,0,0,0.6);
      margin-bottom:8px;
      color:#fff;
      opacity:1;
      position:relative;

      .transition;

      i.fab{
        position:absolute;
        top:4px;
        left:0;
      }

      &:hover{
        opacity:0.7;
      }
    }

    margin-bottom:30px;

  }

}

.copyright{
  color:#fff;
  border-top:1px solid #131313;
  display:block;
  padding-top:20px;
}