/* positioning */
.float-left{
  float:left;
}
.float-left{
  float:right;
}

.clearafter{
  &:after {
    content:"";
    display:block;
    clear:both;
    width:100%;
  }
}

.beforeafter{
  content:"";
  display:block;
}

.absoluteTopLeft{
  position:absolute;
  top:0;
  left:0;
}

.fullWidth{
  width:100%!important;
}