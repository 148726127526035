body{
  background:#eee;
  a{
    color:@blue;
  }
}
.pageContainer{
  min-height:600px;

  &:after{
    .beforeafter;
  }

  .pageheading{
    .karla;
    font-size:18px;
    margin-bottom:0;
    text-transform:uppercase;
    font-weight:700!important;
  }
  .pagesubheading{
    font-size:22px;
    .karla;
    margin-bottom:20px;
  }

}

@media (max-width:480px){
  .pageContainer {
    .pageheading{
      font-size:30px;
    }
    .pagesubheading{
      font-size:18px;
    }
  }
}

.static_content_field{
  h2{
    font-size:18px;
    text-transform:uppercase;
    font-weight:700!important;
  }
}