/* animations */
.transition{
  transition:all .3s ease;
  -moz-transition:all .3s ease;
  -webkit-transition:all .3s ease;
  -o-transition:all .3s ease;
}

.transitionslow{
  transition:all .5s ease;
  -moz-transition:all .5s ease;
  -webkit-transition:all .5s ease;
  -o-transition:all .5s ease;
}

.magnify{
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.magnify2{
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

.blurred{
  -webkit-filter: blur(1.5px);
  -moz-filter: blur(1.5px);
  -o-filter: blur(1.5px);
  -ms-filter: blur(1.5px);
  filter: blur(1.5px);
}