.event_entry{
  margin-bottom:30px;

  .thumb{
    .transition;
    display:block;
    &:hover{
      opacity:.9;
    }
    img{
      margin-bottom:10px;
    }
  }

  h2{
    text-transform:uppercase;
    font-size:20px;
    line-height:24px;
    display:block;
    margin-bottom:10px;
    a{
      .transition;
      color:#131313;
      text-decoration:none;
      &:hover{
        color:@blue;
      }
    }
  }

  p{
    line-height:26px;
  }

  a.readmore{
    width:100px;
    display:block;
    padding:5px 10px;
    background:#131313;
    color:#fff;
    text-decoration:none!important;
    text-transform:uppercase;
    margin-top:10px;
    .unica;
    &:hover{
      background:@blue;
    }
  }

}

.event_sidebar{
  ul{
    margin:0;
    padding:0;
    li{
      list-style:none;
      margin-bottom:15px;
      padding-left:30px;
      line-height:21px;
      border-bottom:1px solid #ccc;
      padding-bottom:30px;
      position:relative;
      top:0;
      left:0;
      .transition;

      &:hover{
        left:5px;
      }

      &:last-child{
        border:none;
      }

      &:before{
        content:"";
        background:url(../img/cat_arrow.png) no-repeat;
        width:13px;
        height:9px;
        display:inline-block;
        float:left;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        margin-top:8px;
        margin-left:-30px;
      }

      a{
        text-transform:uppercase;
        text-decoration:none;
        color:#131313;
        font-size:18px;
        color:#131313;
        display:block;
        .transition;
        &:hover{
          color:@blue;
        }
      }

    }
  }

  .post-edit-link{
    font-size:14px;
    color:@blue;
  }

  h4{
    font-size:18px;
    text-transform:uppercase;
    margin-bottom:20px;
  }

  .section{
    margin-bottom:30px;
    float:right;
    clear:both;
    width:100%;
    max-width:317px;
    //background:#fff;
    padding:0 0 0 50px;
    //&.red{
    //  border-top:3px solid @red;
    //}

    &:last-child{
      margin-bottom:100px;
    }

  }

  .eventdate{
    display:inline-block;
    text-transform:uppercase;
    font-size:14px;
  }

  li.event_li{

    position:relative;
    padding-left:80px;
    padding-bottom:20px;
    min-height:80px;

    .list_title{
      font-size:15px;
      line-height:20px;
    }

    &:before{
      display:none!important;
    }

    span.eventdate{
      display:block;
      font-weight:700;
    }

    .sidethumb{
      width:60px;
      height:60px;
      display:block;
    }

    .thumblink{
      display:block;
      overflow: hidden;
      border-radius:30px;
      margin-right:15px;
      position:absolute;
      top:0;
      left:0;
      .transition;

      &:hover{
        opacity:.9;
      }

    }
  }

}

@media (max-width:1024px){

  .pageBanners{
    margin-bottom:30px;
  }

  .page-template-page-events-php,body.single,body.archive,body.search,body.page,body.is404{

  }

  .container{
    width: 100%;
    max-width: 100%;
  }

  body.ismobile .event_sidebar .section{
    padding-left:0!important;
  }
}

@media (max-width:812px){
  .page-template-page-events-php,body.single,body.archive,body.search,body.page{

    .static_content{
      //padding-top:30px;
    }

    //.col-md-8,.event_sidebar{
    //  max-width:50%!important;
    //  flex:50%!important;
    //  .col-md-6{
    //    max-width:100%!important;
    //    flex:100%!important;
    //  }
    //}
  }

  .event_sidebar{
    .section{
      float:none;
      padding-left:0;
      margin-bottom:0;
    }
  }

}

@media (max-width:640px){
  //.event_sidebar li.event_li {
  //  //padding-left:0;
  //  //.thumblink{
  //  //  display:none;
  //  //}
  //}

  .event_entry{
    text-align:center;
    a.readmore{
      margin-left:auto;
      margin-right:auto;
    }
  }

  .pagination_container{
    margin-bottom:50px;
  }

}