.btn1{
  display:inline-block;
  padding:10px 15px;
  background:#999;
  color:#fff!important;
  text-decoration:none!important;
  text-align:center;
  line-height:24px;
  text-transform:uppercase;
  opacity:.5;
  .transition;

  &:hover,&.active{
    opacity:1;
    background:@blue;
  }

  &.active{
    &:hover{
      opacity:1;
    }
  }

  &.centerwider{
    margin:0 auto;
    width:200px;
    text-align:center;
    display:block;
  }

  &.blue,&.mixitup-control-active{
    background:@blue;
    opacity:1;
    &:hover{
      //background:@yellow;
      color:#fff!important;
    }
  }

}

.white.edit-post-link{
  color:#fff;
  text-decoration:none!important;
}

.bigbuybtn{
  background:@blue;
  color:#fff;
  //font-size:22px;
  ////text-align:center;
  width:200px;
  ////margin:0 auto;
  //padding:15px;
  //border-radius:4px;
  display:block;
  text-decoration:none!important;
  //.transition;
  position:relative;
  top:0px;
  margin-bottom:80px;

  //&:hover{
  //  background:@yellow;
  //  top:-3px;
  //  color:#131313;
  //}

  @media (max-width:480px){
    width:100%!important;
    font-size:20px;
  }

}