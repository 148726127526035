@keyframes revealMenuBar {
  from {
    top:-147px;
  }
  to {
    top:0;
  }
}

body.sticky{
  #header{
    position:fixed;
    //height:60px;
    overflow:hidden;
    animation-name: revealMenuBar;
    animation-duration: .3s;
    animation-timing-function: ease-in-out;
  }

  .top{
    padding-top:0;
    padding-bottom:0;
    //border:none;

    .container{
      padding-top:10px;
      padding-bottom:10px;
    }

    .topButtonsFloat{
      padding-top:7px;
    }
  }
}

/* tablet */
@media (max-width:1024px){

  body{
    padding-top:92px;
  }

  #header{
    .top{
      padding-top:15px;
      padding-bottom:15px;
      //border:none;

      .container{
        padding-top:10px;
        padding-bottom:10px;
      }

      .topButtonsFloat{
        padding-top:7px;
      }

    }
  }

  .showleftAlt{
    display:inline-block!important;
    color:#fff;
    cursor: pointer;
    font-size:20px;
    text-transform:uppercase;
    //background:@red;
    position:absolute;
    top:0;
    left:0;
    padding-left:15px;
    padding-right:15px;
    height:100%;
    line-height:92px;

    i{
      margin-right:3px;
      position:relative;
      top:1px;
    }

    &:hover{
      opacity:.7;
    }

  }

  a.logo{
    position:relative;
    left:50px;
  }

  body.sticky {
    #header {
      .top {
        .container {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .showleftAlt {
      line-height: 72px;
    }

  }

}

@media (max-width:960px){
  a.logo{
    //left:0px;
  }

  .showleftAlt span{
    display:none;
  }

  body.headerSearchFormOpen {
    .topButtonsFloat{
      position:relative;
      top:7px;
    }
  }

}

@media (max-width:812px){
  a.logo{
    //left:10px;
  }
}

@media (max-width:768px){
  a.logo{
    left:40px;
  }
}

/* mobile */

@media (max-width:568px){
  a.logo{
    left:40px;
  }
}

@media (max-width:480px){
  a.logo{
    left:0;
    margin:0 auto;
    display:block;
  }
  .topButtonsFloat{
    position:absolute;
    top:24px;
    right:0;
  }
  .buymerch{
   font-size:20px;
   padding:15px;
   background:none!important;
   border:none!important;

    &:hover{
      opacity:0.7;
    }

    i{
      margin:0;
    }
  }
  .topButtons.websearch{
    display:none!important;
  }
}