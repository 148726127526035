body.single{
  .pageBanners{
    position:relative;
    background-size:cover!important;
    background-position:center center!important;
    //.magnify2;
    //.blurred;
    }
}

.singleProduct{
  .container{
    border-bottom:1px solid #ccc;
  }
}

.productCol{
  position:relative;
  a{
    position:relative;
    display:block;
    width:100%;
    height:100%;
    z-index:5;
    background:none;
    .transition;
    text-decoration:none!important;
    .unica;
    text-transform:uppercase;
    text-align:center;

    opacity:1!important;
    img{
      opacity:1!important;
    }

    &:after{
      .beforeafter;
      .absoluteTopLeft;
      width:100%;
      height:100%;
      background:#000;
      opacity:0;
      .transition;
    }

    span{
      .absoluteTopLeft;
      opacity:0;
      color:#fff;
      text-decoration:none!important;
      top:calc(50% - 10px);
      left:0;
      width:100%;
      text-align:center;
      display:block;
      z-index:5;
      .transition;
    }

    &:hover{
      opacity:.7;
      background:#000;
      &:after{
        opacity:.5;
      }
      span{
        opacity:1;
      }
    }

  }
}

.productGallery{
  min-height:220px;
}

.productdescription{
  padding-top:20px;
  padding-bottom:60px;
  font-size:16px;
  line-height:30px;

  h2{
    margin-bottom:50px!important;
    border-left:2px solid @yellow;
    padding-left:30px;
  }

  p{
    font-size:18px;
    margin-bottom:30px;
    line-height:32px;
  }

  @media (max-width:480px){
    padding-top:10px;
    padding-bottom:10px;
  }

}

.img-responsive{
  max-width:100%;
  width:auto;
  height:auto;
  display:block;
  margin:0 auto;
}

.popupgallery{
  width: 100%;
  div[class*='col-']{
    float:left;

    @media (max-width:480px){
      width:33.33%;

      .productCol{
        height:120px;
        background-size:cover;
        background-position:center center;
        border:2px solid #fff;
      }

      .productCol_2{
        border-left:none!important;
        border-right:none!important;
      }

    }

  }

  a{
    .nooutline;
  }

}

.categorylink{

  margin-bottom:10px!important;

  a{
    color:@blue;
    .transition;
    text-decoration:none!important;
    &:hover{
      opacity:.7;
    }
  }
}

@media (max-width:480px){
  .currency_price{
    display:block;
  }
}