.p404{
  margin-bottom:160px;

  h2{
    text-transform:uppercase;
    font-size:48px;
    span{
      display:block;
      font-size:120px;
      margin-bottom:-50px;
    }
  }

}

.form-control{
  padding:15px;
  border:none;
  height:44px;
  display:inline-block;
  margin-right:5px;
  width:100%;
  max-width:480px;
  &.sm{
    max-width:280px;
  }
}
button.btn1{
  border:none;
  cursor:pointer;
}

/**/
.search_nothingfound{
  font-size:20px;
  text-transform:uppercase;
}

@media (max-width:640px){
  .fwsearch,.fwbutton{
    width:100%!important;
    max-width:100%!important;
    display:block;
    margin-right:auto;
    margin-left:auto;
  }
}