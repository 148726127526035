.pagination_container{
  margin-bottom:100px;
}

.wp-pagenavi{clear:both;}
.wp-pagenavi span.pages{display:none;}
.wp-pagenavi a, .wp-pagenavi span{padding:8px 15px 7px!important;display:inline-block;border:none!important;background:#ccc;font-size:14px;font-weight:700;margin:0 1px 0 0!important;}
.wp-pagenavi span.current{background:@blue;color:#fff;}
.wp-pagenavi a{color:#fff;transition:all 0.2s linear 0s!important;}
.wp-pagenavi a:hover{background:@blue;}
.wp-pagenavi a.last{display:none!important;}