
.page-template-page-sponsors-php{
  .footer{
    margin-top:0;
  }
}

.floatingTitle{
  position:absolute;
  left:30px;
  bottom:30px;
  background:#000;
  .unica;
  text-transform:uppercase;
  font-size:20px;
  display:inline-block;
  text-align:center;
  padding:5px 15px;
  color:#fff;
  font-weight:normal!important;

}

.sponsorinfo{

  .absoluteTopLeft;
  width:100%;
  height:100%;
  z-index:6;

  background:#000;
  background: rgba(0, 0, 0, 0.9);
  color:#fff;
  text-align:center;
  visibility:hidden;

  h2{
    .unica;
    text-transform:uppercase;
    font-size:20px;
    margin-bottom:0
  }
  .url{
    font-size:16px;
  }
  p{
    .karla;
    font-size:15px;
    margin-bottom:15px;
    display:inline-block;

    &:after{
      .transitionslow;
      content:"";
      display:block;
      width:0;
      height:1px;
      background:#fff;
      position:relative;
      top:1px;
      z-index:6;
      margin:0 auto;
    }

    &.active{
      &:after{
        width:100%;
      }
    }

  }
  .discounts{
    .karla;
    clear:both;
    font-size:16px;
    background:#000;
    padding:3px 3px 5px;
    display:block;
    p:last-child{
      margin-bottom:0;
    }
  }
}

.sponsorItem{

  overflow:hidden;

  img{
    .transition;
  }

  .postedit{
    display:none;
  }

  &:hover{
    .floatingTitle{
      //display:none;
    }
    .sponsorinfo{
      visibility:visible;
    }
    .postedit{
      display:block;
    }
  }
}

.sponsorinfo_inner{
  position:relative;
  top:30%;
}

//.sponsorsgrid_2col,sponsorsgrid_5col{
//  visibility:hidden;
//}