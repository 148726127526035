
.mix{
  a{
    position:relative;
    display:block;
    .overlay{
      .absoluteTopLeft;
      width:100%;
      height:100%;
      display:block;
      .transition;
      background:#131313;
      opacity:0;
      font-size:48px;
      color:#fff;
      text-align:center;
      font-style:none!important;
      font-weight:normal!important;
      .unica;
      span{
        display:block;
        width:40px;
        height:40px;
        border:2px solid #fff;
        border-radius:20px;
        line-height:36px;
        position:relative;
        top:calc(50% - 26px);
        margin:0 auto;
      }
      &:hover{
        opacity:.7;
      }
    }
  }
}

.page-id-13{
  .footer{
    margin-top:0!important;
  }
}

.galleryControls{

  background:#131313;

  .btn1.control{
    cursor: pointer;
    border:none;
    float:left;
    width: 16.666%;
  }
  &:after{
    .beforeafter;
    width:100%;
    clear:both;
  }
}

/* Container
---------------------------------------------------------------------- */

.galleryContainer {
  //padding: 1rem;
  text-align: justify;
  font-size: 0.1px;
}

.galleryContainer:after {
  content: '';
  display: inline-block;
  width: 100%;
}

/* Target Elements
---------------------------------------------------------------------- */

.mix,
.gap {
  display: inline-block;
  vertical-align: top;
}

.mix {
  position: relative;
}

.mix {
  visibility: hidden;
}

.mixitup-ready .mix {
  visibility: visible;
}

/* Grid Breakpoints
---------------------------------------------------------------------- */

/* 2 Columns */

.mix,
.gap {
  width: calc(100%/2 - (((2 - 1) * 0rem) / 2));
}

/* 3 Columns */

@media screen and (min-width: 541px) {
  .mix,
  .gap {
    width: calc(100%/3 - (((3 - 1) * 0rem) / 3));
  }
}

/* 4 Columns */

@media screen and (min-width: 961px) {
  .mix,
  .gap {
    width: calc(100%/4 - (((4 - 1) * 0rem) / 4));
  }


  /* sponsors 1280 */

  /* 2 columns */
  .sponsorsgrid_2col {
    .mix,
    .gap {
      width: calc(100%/2 - (((2 - 1) * 0rem) / 2));
      float:left;
    }
  }

  /* 3 columns */
  .sponsorsgrid_3col{
    .mix,
    .gap {
      width: calc(100%/3 - (((3 - 1) * 0rem) / 3));
      float:left;
    }
  }

  /* 5 columns */
  .sponsorsgrid_5col{
    .mix,
    .gap {
      width: calc(100%/5 - (((5 - 1) * 0rem) / 5));
      float:left;
    }
  }


}

/* 5 Columns */

@media screen and (min-width: 1281px) {
  .mix,
  .gap {
    width: calc(100%/5 - (((5 - 1) * 0rem) / 5));
  }

  /* 2 columns */
  .sponsorsgrid_2col{
    .mix,
    .gap {
      width: calc(100%/2 - (((2 - 1) * 0rem) / 2));
      float:left;
    }
  }

  /* 3 columns */
  .sponsorsgrid_3col{
    .mix,
    .gap {
      width: calc(100%/3 - (((3 - 1) * 0rem) / 3));
      float:left;
    }
  }

  /* 5 columns */
  .sponsorsgrid_5col{
    .mix,
    .gap {
      width: calc(100%/5 - (((5 - 1) * 0rem) / 5));
      float:left;
    }
  }

}

@media (max-width:480px){
  /* sponsors 480 */

  /* 2 columns */
  .sponsorsgrid_2col {
    .mix,
    .gap {
      width: calc(100%/1 - (((1 - 1) * 0rem) / 1));
      float:left;
    }
  }

  /* 3 columns */
  .sponsorsgrid_3col{
    .mix,
    .gap {
      width: calc(100%/2 - (((2 - 1) * 0rem) / 2));
      float:left;
    }
  }

  /* 5 columns */
  .sponsorsgrid_5col{
    .mix,
    .gap {
      width: calc(100%/3 - (((3 - 1) * 0rem) / 3));
      float:left;
    }
  }

  h2.floatingTitle{
    display:none!important;
  }

  body{
    .sponsorinfo_inner{
      top:30px!important;
    }
  }

}


.page-template-page-photos-php,.page-template-page-sponsors-php{
  .pageContainer{
    background:#131313;
  }
}

@media (max-width:1280px){
  .galleryControls {
    .btn1.control {
      width:auto;
    }
  }
}