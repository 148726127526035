
.menu-item a{
  line-height:64px;
  text-decoration:none!important;
  color:#fff!important;
  font-weight:normal!important;
  position:relative;
  background:none!important;
  float:left;

  &:after{
    .transition;
    position:absolute;
    content:"";
    display:block;
    width:100%;
    height:2px;
    top:0;
    left:0;
    background:#030303;
  }

  &:hover{
    opacity:1!important;
    &:after{
      background:@yellow;
    }
  }

  &.active{
    opacity:1!important;
    &:after{
      background:@red;
    }
  }

  @media (max-width:1024px){
    display:none!important;
  }

}

.current_page_item.menu-item a{
  opacity:1!important;
  &:after{
    background:@red;
  }
}

#showLeft,.menu-item a{
  .transition;
  display:inline-block;
  background:#000;
  color:#fff;
  text-align:center;
  border:none;
  text-transform:uppercase;
  font-weight:700;
  opacity:.7;
  cursor: pointer;
  width:120px;
  height:64px;
  background:none;
  font-size:16px;

  i{
    margin-right:5px;
  }

  &:hover,&.active{
    opacity:1;
    background:@dark;
  }
}

.menu-item-edit-page{
  float:right;
}

@media (max-width:640px){
  .buymerch{
    display:none;
  }
  .headerSearchFormOpen{
    #header{
      min-height:92px;
    }
    a.logo{
      display:none;
    }
    .topButtonsFloat form{
      float:none;
      width:calc(100% - 50px);
    }
    .topButtonsFloat{width:calc(100% - 50px);}
    .webSearchField{
      width:100%;
      display:block;
    }
  }
}