.single_content{

  margin-bottom:100px;

  h1,h2,h3,h4,h5,h6{
    margin-bottom:30px;
  }

  h1{
    font-size:23px;
    line-height:28px;
    text-transform:uppercase;
  }
  h2{
    font-size:20px;
    text-transform:uppercase;
  }
  h3{
    font-size:18px;
  }
  h4{
    font-size:16px;
  }
  h5{
    font-size:14px
  }
  h6{
    font-size:12px;
  }

  p{
    line-height:30px;
    font-size:16px;
    margin-bottom:30px;
  }

  ul,ol{
    margin-bottom:30px;
    li{
      margin-bottom:10px;
    }
  }

  img{
    width:auto;
    max-width:100%;
  }

  img.alignleft{
    float:left;
    margin-right:30px;
    margin-bottom:30px;
  }

  img.alignright{
    float:right;
    margin-right:30px;
    margin-bottom:30px;
  }

  img.aligncenter{
    display:block;
    margin:0 auto 30px;
  }

  hr{
    margin-top:30px;
    margin-bottom:30px;
  }

}

.more_reading{
  h4{
    font-size:18px;
    text-transform:uppercase;
    margin-bottom:20px;
  }
}

.social_heading,
.date_heading{
  text-transform:uppercase;
  color:#131313;
  font-weight:700;
  float:right;
  a,span{
    font-weight:normal!important;
    display:inline-block;
    width:30px;
    height:30px;
    text-align:center;
    border:1px solid #131313;
    color:#131313;
    border-radius:15px;
    margin-right:5px;
    .transition;
    &:hover{
      background:@blue;
      color:#fff;
      border-color:#fff;
    }
  }

  i{
    position:relative;
    top:3px;
  }

}

.social_heading{
  float:left;
}

.postmeta{
  &:after{
    .beforeafter;
    clear:both;
  }
  margin-bottom:50px;
}

@media (max-width:480px){
  .postmeta{
    margin-bottom:30px;
  }
}