body{
  padding-top:157px;
  position:relative;
}

#header{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  z-index:9990;
  background:#030303;
}

.top{
  padding-top:26px;
  padding-bottom:26px;
  border-bottom:1px solid #131313;
}

/* header left side */

a.logo{
  .unica;
  font-size:20px;
  text-transform:uppercase;
  letter-spacing: 3px;
  color:#fff;
  text-decoration:none;
  text-indent:-9999px;
  background:url(../img/logo2.png) no-repeat;
  display:inline-block;
  width:247px;
  height:41px;
  //opacity:1;
  .transition;

  @media (max-width:320px){
    width:220px;
    background-size:contain!important;
  }

  //&:hover{
  //  opacity:.8;
  //}
}

/* header right side */

.topButtonsFloat{
  padding-top:3px;

  form{
    display:inline-block;
    float:left;
  }
}

.topButtons{
  font-size:16px;
  color:#fff;
  margin-left:25px;
  text-decoration:none!important;
  .transition;
}

.buymerch{
  .unica;
  border:1px solid #fff;
  color:#fff;
  padding:10px 30px;
  text-transform:uppercase;
  font-size:14px;
  text-decoration:none!important;
  i{
    margin-left:5px;
  }
  &:hover{
    color:#fff;
    background:@blue;
    border-color:@blue;
  }
}

.websearch{
  color:#fff!important;
  &:hover{
    opacity:.7!important;
  }
}

.webSearchField{
  border:none;
  height:39px;
  float:left;
  margin-top:-7px;
  background:#171717;
  color:#666;
  padding-left:15px;
  padding-right:15px;
  .nooutline;
  .transition;

  &:focus{
    color:#fff;
  }

  & + .topButtons{
    margin-left:17px;
  }
}

/* search field active */

body.headerSearchFormOpen{

  @media (max-width:960px){

    .topButtonsFloat,.topButtonsFloat form{
      display:block!important;
      padding-top:0!important;
    }

    .buymerch{
      display:none;
    }
  }

  @media (max-width:480px){

    .topButtonsFloat,.topButtonsFloat form{
      float:none!important;
      width:100%;
    }

    .topButtonsFloat{
      position:relative;
    }

    .websearch{
      position:absolute;
      top:0;
      right:0;
      padding:0 15px;
    }

    .webSearchField{
      float:none;
      width:100%;
      height:37px!important;
    }

    a.logo{
      display:none;
    }

    /**/
    &.sticky{
      .showleftAlt{
        display:none!important;
      }
      .topButtonsFloat{
        margin-top:3px;
      }
    }
  }

  .websearch{
    margin-left:15px;
  }

}

@media (max-width:480px){
  .buymerch{
    //background:@red;
    border-color:#000;
  }
  .buymerch span{
    display:none;
  }
}

@media (max-width:1024px){
  .showleftAlt span{
    display:none!important;
  }
}