/* overlay */

@keyframes overlay {
  from {opacity:0;}
  to {opacity:.8;}
}

// menu, search input field shown

body.menu-open .menuOverlay,body.headerSearchFormOpen .searchFormOverlay{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#000;
  opacity:.8;
  z-index:9998;
  cursor: pointer;
  animation-name: overlay;
  animation-duration: .3s;
  animation-timing-function: ease-in-out;
}

body.menu-open .menuOverlay{
  z-index:9998;
}

body.headerSearchFormOpen {
  #header{
    z-index:9999;
  }
}