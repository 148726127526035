@sliderHeight:642px;

.homeSlider {
  height:@sliderHeight;
  width:100%;
  position:relative;
  background:#030303;
  margin-bottom:0!important;

  .sliderItem {

    &:before {
      content:"";
      display:block;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background:#000;
      opacity:.1;
      z-index:5;
    }

    background-size:cover!important;
    background-repeat:no-repeat;
    background-position:center center;
    width:100%;
    height:@sliderHeight;
    z-index:3;
  }

}

/* slider plugin / owl carousel */

.owl-carousel,.owl-carousel2 {

  .owl-stage-outer {
    z-index:3;
  }

  .owl-item {
    height:@sliderHeight;
  }

  .owl-dots {
    //position:absolute;
    //left:0;
    //right:0;
    //bottom:0;
    z-index:5;
    width:100%;
    text-align:center;
    padding-bottom:10px;
  }

  button.owl-dot {

    margin-top:15px;

    &.active {
      opacity:.2;
    }

    background:#fff!important;
    display:inline-block;
    width:10px;
    height:10px;
    text-align:center;
    border-radius:5px;
    opacity:.1;
    margin-left:5px;
    margin-right:5px;
  }

}

/* slider height for responsive */

@media (max-width:1024px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item{
    height:510px;
  }
}

@media (max-width:960px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item{
    height:480px;
  }
}

@media (max-width:812px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item{
    height:370px;
  }
}

@media (max-width:768px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item{
    height:380px;
  }
}

@media (max-width:736px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item{
    height:360px;
  }
}

@media (max-width:683px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item{
    height:340px;
  }
}

@media (max-width:667px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item{
    height:320px;
  }
}

@media (max-width:640px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item {
    height: 315px;
  }
}

@media (max-width:600px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item {
    height: 290px;
  }
}

@media (max-width:568px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item {
    height: 280px;
  }
}

@media (max-width:480px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item {
    height: 230px;
  }
}

@media (max-width:414px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item {
    height: 200px;
  }
}

@media (max-width:375px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item {
    height: 180px;
  }
}

@media (max-width:320px){
  .homeSlider,.homeSlider .sliderItem,.owl-carousel .owl-item {
    height: 160px;
  }
}