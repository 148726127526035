.related{

  padding-top:60px;

  h2{
    .unica;
    font-size:20px;
    text-transform:uppercase;
    margin-bottom:40px;
  }

  &.prefooter{
    margin-bottom:60px;
  }

  margin-bottom:50px;

}