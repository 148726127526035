.button {
  //display: block;
  //border: none;
  position: relative;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  opacity:1;
}
.button.one:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.button.one:hover:after {
  width: 120%;
  background-color: rgba(255,255,255,0);

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

/* Two */
.button.two {
  background-repeat: no-repeat;
  background-position: -120px -120px, 0 0;

  background-image: -webkit-linear-gradient(
          top left,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 37%,
          rgba(255, 255, 255, 0.8) 45%,
          rgba(255, 255, 255, 0.0) 50%
  );
  background-image: -moz-linear-gradient(
          0 0,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 37%,
          rgba(255, 255, 255, 0.8) 45%,
          rgba(255, 255, 255, 0.0) 50%
  );
  background-image: -o-linear-gradient(
          0 0,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 37%,
          rgba(255, 255, 255, 0.8) 45%,
          rgba(255, 255, 255, 0.0) 50%
  );
  background-image: linear-gradient(
          0 0,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 37%,
          rgba(255, 255, 255, 0.8) 45%,
          rgba(255, 255, 255, 0.0) 50%
  );

  -moz-background-size: 250% 250%, 100% 100%;
  background-size: 250% 250%, 100% 100%;

  -webkit-transition: background-position 0s ease;
  -moz-transition: background-position 0s ease;
  -o-transition: background-position 0s ease;
  transition: background-position 0s ease;
}

.button.two:hover {
  background-position: 0 0, 0 0;

  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
}