.lds-ring {
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  margin:0 auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*
  <div style='display:none' class="lds-ring spinner"><div></div><div></div><div></div><div></div></div>
*/

.sponsorItem{
  .spinner{
    margin-top:15px;
    margin-bottom:15px;
  }
}

.loadingvideo{
  padding-top:40px;
  text-align:center;
  padding-bottom:40px;
  text-transform:uppercase;
  p{
    margin-bottom:0;
  }
  .lds-ring div{
    border-color: #131313 transparent transparent transparent;
  }
}